/*
Theme Name: Rinox
Author: Adfab
Author URI: https://adfab.com
Description: Theme developed by Adfab ofr Rinox project
Version: 1.0
*/

@import './general';
@import './components/bg';
